<template>
  <v-btn
    class="btn"
    color="secondary"
    :min-width="40"
    :width="width"
    :height="height"
    :disabled="registerMembersState !== null && !registerMembersState"
    @click="downloadRegister"
  >
    <div class="d-flex justify-center align-center">
      <div
        v-if="registerMembersState !== null"
      >
        <div v-if="!registerMembersState">
          <slot name="progress"/>
        </div>
        <div v-else>
          <slot name="generate"/>
        </div>
      </div>
      <div v-else>
        <slot name="download"/>
      </div>
    </div>
  </v-btn>
</template>

<script>
import { downloadRegisterMembers, generateRegisterMembers } from '../../helpers/api'
import { downloadFileFromArrayBuffer, validateRequestData } from '../../helpers/helper'
import { loadRegisterMembersState, saveRegisterMembersState } from '../../helpers/history'

export default {
  name: 'exportMembers',
  props: {
    sroId: {
      type: Number,
      required: true
    },
    numberSro: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      registerMembersState: null,
      registerMembersStateInterval: null
    }
  },
  methods: {
    async downloadRegister () {
      if (this.registerMembersState) {
        this.registerMembersState = null
        const responseData = await downloadRegisterMembers(this.sroId)

        if (!responseData.success) {
          this.putErrorMessage(responseData.message)
          return
        }

        downloadFileFromArrayBuffer(responseData.data, 'Реестр членов СРО №' + (this.numberSro ? ` ${this.numberSro}` : ''), responseData.contentType)
        this.putErrorMessage('')
      } else {
        this.putErrorMessage('')
        await this.generateRegisterMembers()
      }
    },
    async generateRegisterMembers () {
      const responseData = await generateRegisterMembers(this.sroId)

      const validateResponse = validateRequestData(responseData)
      if (!validateResponse.success) {
        return
      }

      const oldState = this.registerMembersState
      this.registerMembersState = responseData.data.actualRegisterIsExists
      if (!this.registerMembersState) {
        if (!this.registerMembersStateInterval) {
          this.registerMembersStateInterval = setInterval(
            this.generateRegisterMembers,
            process.env.VUE_APP_TIMEOUT_REQUEST_EXPORT_MEMBERS_STATUS
          )
        }
      } else {
        if (this.registerMembersStateInterval) {
          clearInterval(this.registerMembersStateInterval)
          this.registerMembersStateInterval = null
        }
        if (!oldState) {
          await this.downloadRegister()
        }
      }
    },

    loadRegisterState () {
      if (!this.sroId) {
        return
      }

      this.registerMembersState = loadRegisterMembersState(this.sroId)
      if (this.registerMembersState === null) {
        return
      }
      if (!this.registerMembersState && !this.registerMembersStateInterval) {
        this.registerMembersStateInterval = setInterval(
          this.generateRegisterMembers,
          process.env.VUE_APP_TIMEOUT_REQUEST_EXPORT_MEMBERS_STATUS
        )
      }
    },
    putErrorMessage (message) {
      this.$emit('put-error-message', message)
    }
  },
  watch: {
    sroId () {
      this.loadRegisterState()
    },
    registerMembersState (value) {
      saveRegisterMembersState(this.sroId, value)
    }
  },

  beforeDestroy () {
    if (this.registerMembersStateInterval) {
      clearInterval(this.registerMembersStateInterval)
    }
  }
}
</script>

<style scoped>

</style>
