<template>
  <div class="max-width">
    <div class="breadcrumbs" v-if="sro">
      <router-link :to="{ name: 'SroList' }" exact>Реестр СРО</router-link>
      /
      <span>{{ sro.short_description }}</span>
    </div>
    <div class="sro-item">
      <div class="page-title" v-if="sro">{{ sro.full_description }}</div>
      <warn-message v-if="sro && sro.deactivate_message">Исключены сведения о СРО из государственного реестра СРО
        {{ sro.deactivate_message }}
      </warn-message>
      <error-message v-if="errorMessage">
        {{ errorMessage }}
      </error-message>
      <success-message v-if="successMessage">
        {{ successMessage }}
      </success-message>
      <spinner-component
        v-if="!sro && !errorMessage && spinnerIsShow"
      />
      <div v-if="sro">
        <div
          class="sro-item__info"
        >
          <div class="sro-item__row">
            <div class="sro-item__row_title">Полное наименование:</div>
            <div class="sro-item__row_value">{{ sro.full_description }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Сокращенное наименование:</div>
            <div class="sro-item__row_value">{{ sro.short_description }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Регистрационный номер в государственном реестре саморегулируемых организаций:
            </div>
            <div class="sro-item__row_value">{{ sro.registration_number }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">ИНН:</div>
            <div class="sro-item__row_value">{{ sro.inn }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">ОГРН:</div>
            <div class="sro-item__row_value">{{ sro.ogrn }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Адрес места нахождения:</div>
            <div class="sro-item__row_value">{{ sro.place }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Контактные телефоны:</div>
            <div class="sro-item__row_value">{{ sro.phone }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Адрес электронной почты:</div>
            <div class="sro-item__row_value">{{ sro.email }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Адрес официального сайта в информационно-телекоммуникационной сети
              "Интернет":
            </div>
            <div class="sro-item__row_value"
                 v-html="`<a href=${getSroSiteUrl(sro.site)} target='_blank'>${sro.site}</a>`"
            />
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Дата изменения информации:</div>
            <div class="sro-item__row_value">{{ lastUpdateDate }}</div>
          </div>
        </div>
        <div class="sro-item__buttons">
          <v-btn
            class="btn"
            color="secondary"
            @click="$router.push({name: 'MemberListBySro', params: { id: sro.id }} )"
          >
            Реестр членов СРО
          </v-btn>
          <v-btn
            v-if="sro.compensation_fund_link"
            class="btn btn-small-window"
            color="secondary"
            min-height="50"
            @click="redirectToCompensationFund"
          >
            Сведения о<br class="v-btn__carry">
            компенсационном фонде СРО
          </v-btn>
          <v-btn
            v-if="sro.compensation_fund_link"
            class="btn btn-large-window"
            color="secondary"
            @click="redirectToCompensationFund"
          >
            Сведения о компенсационном фонд СРО
          </v-btn>
          <export-members
            :sro-id="sro.id"
            :number-sro="sro.registration_number"
            @put-error-message="showError"
          >
            <template v-slot:progress>
              <v-progress-circular
                :size="24"
                color="primary"
                indeterminate
              >
              </v-progress-circular>
              <span>
              Формирование реестра членов СРО
            </span>
            </template>
            <template v-slot:generate>
              <v-icon style="font-size: 20px"
              >
                mdi-download
              </v-icon>
              <span>
              Скачать реестр членов СРО
            </span>
            </template>
            <template v-slot:download>
              <v-icon style="font-size: 20px"
              >
                mdi-download
              </v-icon>
              <span> Выгрузить реестр членов СРО</span>

            </template>
          </export-members>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  getSroItem
} from '../../helpers/api'
import { formatDateTime } from '../../helpers/format'
import ErrorMessage from '../../components/alerts/ErrorMessage'
import SuccessMessage from '../../components/alerts/SuccessMessage'
import WarnMessage from '../../components/alerts/WarnMessage'
import SpinnerComponent from '../../components/spinner/Spinner'
import ValidationMixin from '../../mixins/ValidationMixin'
import ExportMembers from '../../components/buttons/ExportMembers'

export default {
  name: 'SroItem',
  components: { ErrorMessage, SpinnerComponent, SuccessMessage, WarnMessage, ExportMembers },
  mixins: [ValidationMixin],
  data: () => ({
    sro: null,
    lastUpdateDate: '',
    errorMessage: '',
    successMessage: '',
    spinnerIsShow: false,
    registerMembersState: null,
    registerMembersStateInterval: null
  }),
  methods: {
    async getSro (id) {
      this.errorMessage = ''
      this.spinnerIsShow = true
      const requestData = await getSroItem(id)
      this.spinnerIsShow = false

      if (!this.validateRequestData(requestData)) {
        return
      }

      this.sro = requestData.data
      this.lastUpdateDate = requestData.lastUpdateDate
    },
    formatDateTime (dateTime) {
      if (!dateTime) return
      return formatDateTime(new Date(dateTime))
    },
    redirectToCompensationFund () {
      window.location.href = this.sro.compensation_fund_link
    },
    getSroSiteUrl (site) {
      if (site && site.trim() === '') {
        return ''
      }
      if (/^https?:\/\//.test(site)) {
        return site
      }

      return 'https://' + site
    },
    showError (message) {
      this.errorMessage = message
    }
  },
  async mounted () {
    await this.getSro(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
